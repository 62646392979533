.ant-layout-header {
    background-color: black;
}
.ant-layout-sider {
    background-color: black;
}
.ant-menu-overflow-item {
    background-color: #001529;
}
.ant-menu.ant-menu-dark {
    background-color: black;
}
.ant-layout-sider-zero-width-trigger {
    background-color: black;
}
@media
	only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 1024px)  {

		/* Force table to not be like tables anymore */
		table, thead, tbody, th, td, tr {
			display: block;
		}

		/* Hide table headers (but not display: none;, for accessibility) */
		thead tr {
			position: absolute;
			top: -9999px;
			left: -9999px;
		}

    tr {
      margin: 0 0 1rem 0;
    }
      
    tr:nth-child(odd) {
      background: #ccc;
    }
    
		td {
			/* Behave  like a "row" */
			border: none;
			border-bottom: 1px solid #eee;
			position: relative;
			padding-left: 50%;
		}

		td:before {
			/* Now like a table header */
			position: absolute;
			/* Top/left values mimic padding */
			top: 0;
			left: 6px;
			width: 45%;
			padding-right: 10px;
			white-space: nowrap;
		}
        /* td:nth-of-type(1):before { content: "Brand"; }
		td:nth-of-type(2):before { content: "Name"; }
		td:nth-of-type(3):before { content: "Model"; }
		td:nth-of-type(4):before { content: "Actions"; } */
    }