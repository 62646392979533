
.inputSearch {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 5px;
    margin-bottom: 10px;
}

.inputSearch>div {
    position: relative;
    width: fit-content;
}

.inputSearch input::placeholder {
    color: #d9d9d9 !important;
}

.inputSearch .svgIcon {
    position: absolute;
    top: 50%;
    bottom: 50%;
    left: 1px;
    transform: translate(0, -50%);
    width: 15px;
    height: 15px;
}

.inputSearch>div>input {
    padding: 4px 16px;
    border: 0;
    box-shadow: 0 0 11px 5px rgba(242, 242, 242, 0.2);
    border-radius: 2px;    
}
.inputSearch>div>input:focus {
    outline: none;
}
.inputSearch>div span {
    position: absolute;
    top: 50%;
    bottom: 50%;
    right: 1%;
    transform: translate(-50%, -50%);
    width:20px;
    height: 20px;
    border-radius: 100%;
    background-color: #f1f1f1;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputSearch > button {
    box-shadow: 0 0 11px 5px rgba(0, 0, 0, 0.09);
    border: 0;
    background-color: transparent;
    align-self: stretch;
    cursor: pointer;
    border-radius: 2px;
}