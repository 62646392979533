.authentication{
  background-color: rgb(25, 28, 31);

    padding: 50px 74px;
    border-radius: 2px;
    box-shadow: rgba(0,0,0,0.1) 0px 0px 5px;
    transition: width 2s, height 2s, transform 2s;
    max-width:450px;
    width: 100%;
}

.fullBG{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form {
  max-width: 450px;
}
.login-form-forgot, .login-form-register {
  margin: 10px 0;
  display:block;
  float: none;
}
.ant-col-rtl .login-form-forgot {
  float: left;
}
.login-form-button {
  width: 100%;
}
.logo-slogan {
  border-radius: 20px;
}

.logo-slogan img{
  width: 100%;
  max-width: 450px;
  padding: 24px 0 0;

}
.authentication .ant-btn-primary {
  background-color: #e6f46a!important;
  border-color: #e6f46a !important;
  color: #111 !important;
}

@media only screen and (max-width: 768px) {
  .logo-slogan img{
    max-width: 280px;
    padding: 20px 0;
  }
}